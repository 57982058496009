// Color variables
$wood-charcoal-color: #464646;
$tamrillo-color: #902516;
$crusoe-color: #175434;
$ruri-blue-color: #2948a1;
$biscay-color: #1c3c6b;
$indochine-color: #bf7006;
$milano-red-color: #b92400;
$cherry-pie-color: #372c56;
$plum-cheese-color: #630b21;
$cod-gray-color: #111;

$contrast-simbook-color: #962306; //SMOKING RED
$contrast-simbook-color-icon: $milano-red-color; //CHESTNUT PLUM
$contrast-simpath-color: #67410f; //PULLMAN BROWN
$contrast-simpath-color-icon: $indochine-color; //HAIRY BROWN
$contrast-resource-color: #175434; //CRUSOE
$contrast-resource-color-icon: #185a3b; //BILLIARD TABLE
$contrast-overdue-color: #8c2c1e; //BORSCHT
$contrast-exam-color: $plum-cheese-color; //PLUM CHEESE
$contrast-group-color: #464646; //WOOD CHARCOAL
$contrast-group-color-icon: #545454; //BLACK SPACE
$contrast-lesson-color: $cherry-pie-color; //CHERRY PIE
$contrast-lesson-color-icon: #544484; //GIGAS
$contrast-focus-color: #2948a1; //RURI BLUE

@import '~bootstrap/scss/functions.scss';

.visually-hidden,
.a11y-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  background: #fff;
  color: #000;

  @media print {
    display: none;
  }
}

/**
 * common, a11y-specific elements
 *
 * - most are .a11y-only (invisible to most users) but
 * - if necessary, can be uncommented and styled here
 *
 **/

// .a11y-only-page-header {}

.a11y-block-bypass {
  position: fixed;
  top: -200vh;
  background: #fff;
  color: #000;
  padding: 1rem 2rem;
  font-size: 1.5rem;

  &:focus {
    top: 54px;
    left: 50%;
    z-index: 1032; // Higher than Launch.module.scss
    transform: translateX(-50%);
  }

  @media print {
    display: none;
  }
}

// .a11y-table-caption {}

// .a11y-link-label {}

// .a11y-submit-button {}

// headings promoted to adhere to WCAG 2.0 success
// criterion 2.4.10 "proper nesting" requirement
// but styled below to retain original appearance
.a11y-heading-1 {
  font-size: 1.62rem;
}
.a11y-heading-3 {
  font-size: 1.35em;
  line-height: 1.1;
}
.a11y-heading-4 {
  font-size: 1.3125rem;
}
.a11y-heading-5 {
  font-size: 1.08rem;
}
.a11y-heading-6 {
  font-size: 0.85em;
  font-weight: bold;
  line-height: 1;
  margin-top: 12px;
}
.a11y-normalize-heading {
  font-size: inherit;
  padding: inherit;
  margin: inherit;
  line-height: inherit;
}

// Focus outline transition
[tabindex]:not([tabindex='-1']),
a,
button,
input,
select,
textarea {
  outline-width: 2px;
  outline-offset: 5px;
  transition: outline-offset 0.2s linear;

  @media (prefers-reduced-motion) {
    transition: none;
  }

  &:focus {
    outline-offset: 2px;
  }
}

textarea,
input[type='text'],
input[type='password'],
input[type='datetime'],
input[type='datetime-local'],
input[type='date'],
input[type='month'],
input[type='time'],
input[type='week'],
input[type='number'],
input[type='email'],
input[type='url'],
input[type='search'],
input[type='tel'],
input[type='color'],
.uneditable-input {
  border-color: #777 !important;
}

:global {
  a:not(.btn):not(.action-btn):not([role]):not([type='button']):not(
      .help-btn
    ):not(.sim-exam-btn):not(span):not(svg):not(.exempt-color-change):not(
      path
    ) {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

.required {
  color: #d70707;
}

// High-contrast theme is our workaround for WCAG 2.0
// success criteria 1.4.3 and 1.4.6 minimum and enhanced
// contrast requirements
//
// Rather than radically altering the SIMnet color scheme
// for everyone, we're simply providing a toggle in the user
// settings which satisfies the requirements as an "alternative"
// web page we can offer to the visually challenged

.high-contrast-theme,
.welcome-page {
  color: #000;

  input::placeholder,
  .public-DraftEditorPlaceholder-inner {
    color: $cod-gray-color;
  }

  // The next line MUST stay on one line to work
  a:not(.btn):not(.action-btn):not([role]):not([type='button']):not(
      .help-btn
    ):not(.sim-exam-btn):not(span):not(svg):not(.exempt-color-change):not(path),
  .rbt-token:not(.rbt-token-active),
  .anchor--color {
    color: $biscay-color;
  }

  > #header,
  > #content {
    .alert-warning--color,
    .alert-warning--color h4 {
      color: #5f4b29 !important;
    }

    .alert-success--color {
      color: #264e27 !important;
    }
    .alert-info--color {
      color: #235067 !important;
    }
    .alert-error--color {
      color: #6b2c2a !important;
    }
  }

  > #header {
    .navbar-item--color {
      color: #fff !important;
      &#profile,
      &#collapsedProfile {
        color: #ffd540 !important;
      }
    }
  }

  .question-list-modal-high-contrast {
    td.tbl-col-cell.tbl-col-3.attempts span {
      color: black !important;
    }
  }

  .calendar-grid-high-contrast th,
  .calendar-grid-high-contrast tbody div.day-cell-high-contrast-date {
    color: black !important;
  }

  .date-inset-high-contrast.date-inset-high-contrast-overdue {
    background-color: #fdb4a9;
  }

  .results-info-panel-high-contrast {
    tr {
      th,
      td {
        color: black !important;
      }
    }
  }

  .table th,
  .table td {
    color: black !important;
  }

  .table th.sortable {
    color: $biscay-color !important;
  }

  .breadcrumb-item.active,
  .assignmentsTitle .tbl-title-cell {
    color: $wood-charcoal-color;
  }

  .high-contrast-opacity--point-7 {
    opacity: 0.7 !important;
  }

  .high-contrast-color--biscay {
    color: $biscay-color !important;
  }

  .high-contrast-color--black {
    color: black !important;
  }
  .high-contrast-bg-color--black {
    background-color: black !important;
  }

  .high-contrast-color--cod-gray {
    color: $cod-gray-color !important;
  }
  .high-contrast-bg-color--cod-gray {
    background-color: $cod-gray-color !important;
  }

  .high-contrast-color--white {
    color: white !important;
  }
  .high-contrast-bg-color--white {
    background-color: white !important;
  }

  .high-contrast-color--tamrillo {
    color: $tamrillo-color !important;
  }
  .high-contrast-bg-color--tamrillo {
    background-color: $tamrillo-color !important;
  }
  .high-contrast-border-color--tamrillo {
    border-color: $tamrillo-color !important;
  }
  .high-contrast-color--crusoe {
    color: $crusoe-color !important;
  }
  .high-contrast-svg-color--crusoe {
    svg {
      color: $crusoe-color !important;
    }
  }
  .high-contrast-bg-color--crusoe {
    background-color: $crusoe-color !important;
  }
  .high-contrast-border-color--crusoe {
    border-color: $tamrillo-color !important;
  }
  .high-contrast-color--ruri-blue {
    color: $ruri-blue-color !important;
  }
  .high-contrast-svg-color--ruri-blue {
    svg {
      color: $ruri-blue-color !important;
    }
  }
  .high-contrast-bg-color--ruri-blue {
    background-color: $ruri-blue-color !important;
  }
  .high-contrast-border-color--ruri-blue {
    border-color: $ruri-blue-color !important;
  }
  .high-contrast-color--indochine {
    color: $indochine-color !important;
  }
  .high-contrast-svg-color--indochine {
    svg {
      color: $indochine-color !important;
    }
  }
  .high-contrast-bg-color--indochine {
    background-color: $indochine-color !important;
  }
  .high-contrast-border-color--indochine-color {
    border-color: $indochine-color !important;
  }
  .high-contrast-color--plum-cheese {
    color: $plum-cheese-color !important;
  }
  .high-contrast-svg-color--plum-cheese {
    svg {
      color: $plum-cheese-color !important;
    }
  }
  .high-contrast-bg-color--plum-cheese {
    background-color: $plum-cheese-color !important;
  }
  .high-contrast-border-color--plum-cheese-color {
    border-color: $plum-cheese-color !important;
  }
  .high-contrast-color--cherry-pie {
    color: $cherry-pie-color !important;
  }
  .high-contrast-svg-color--cherry-pie {
    svg {
      color: $cherry-pie-color !important;
    }
  }
  .high-contrast-bg-color--cherry-pie {
    background-color: $cherry-pie-color !important;
  }
  .high-contrast-border-color--cherry-pie-color {
    border-color: $cherry-pie-color !important;
  }
  .high-contrast-color--milano-red {
    color: $milano-red-color !important;
  }
  .high-contrast-svg-color--milano-red {
    svg {
      color: $milano-red-color !important;
    }
  }
  .high-contrast-bg-color--milano-red {
    background-color: $milano-red-color !important;
  }
  .high-contrast-border-color--milano-red-color {
    border-color: $milano-red-color !important;
  }

  .high-contrast-cal-lesson-badge--start-date {
    @extend .high-contrast-bg-color--cherry-pie;
    ::before {
      border-left-color: $contrast-lesson-color !important;
    }
  }
  .high-contrast-cal-simbook-badge--start-date {
    @extend .high-contrast-bg-color--milano-red;
    ::before {
      border-left-color: $milano-red-color;
    }
  }
  .high-contrast-cal-exam-badge--start-date {
    @extend .high-contrast-bg-color--plum-cheese;
    ::before {
      border-left-color: $contrast-exam-color;
    }
  }
  .high-contrast-cal-project-badge--start-date {
    @extend .high-contrast-bg-color--ruri-blue;
    ::before {
      border-left-color: $ruri-blue-color;
    }
  }
  .high-contrast-cal-simpath-badge--start-date {
    @extend .high-contrast-bg-color--indochine;
    ::before {
      border-left-color: $indochine-color;
    }
  }
  .high-contrast-cal-resource-badge--start-date {
    @extend .high-contrast-bg-color--crusoe;
    ::before {
      border-left-color: $crusoe-color;
    }
  }
  .high-contrast-cal-lesson-badge--due-date {
    @extend .high-contrast-bg-color--cherry-pie;
    ::before {
      border-right-color: $contrast-lesson-color !important;
    }
  }
  .high-contrast-cal-simbook-badge--due-date {
    @extend .high-contrast-bg-color--milano-red;
    ::before {
      border-right-color: $milano-red-color;
    }
  }
  .high-contrast-cal-exam-badge--due-date {
    @extend .high-contrast-bg-color--plum-cheese;
    ::before {
      border-right-color: $contrast-exam-color;
    }
  }
  .high-contrast-cal-project-badge--due-date {
    @extend .high-contrast-bg-color--ruri-blue;
    ::before {
      border-right-color: $ruri-blue-color;
    }
  }
  .high-contrast-cal-simpath-badge--due-date {
    @extend .high-contrast-bg-color--indochine;
    ::before {
      border-right-color: $indochine-color;
    }
  }
  .high-contrast-cal-resource-badge--due-date {
    @extend .high-contrast-bg-color--crusoe;
    ::before {
      border-right-color: $crusoe-color;
    }
  }

  .high-contrast-launch-btn {
    background-color: $tamrillo-color !important;
    border-color: $tamrillo-color !important;

    &:focus,
    &:active,
    &:hover {
      background-color: lighten($tamrillo-color, 10) !important;
      border-color: lighten($tamrillo-color, 10) !important;
    }
  }

  .assignments-filter-high-contrast-overdue label {
    color: $contrast-overdue-color !important;
  }

  .assignments-filter-high-contrast,
  .grades-filter-high-contrast {
    color: $contrast-group-color;

    span {
      color: black !important;
    }
  }

  .grade-view-nav-item:not(.grade-view-nav-item-active)
    .grades-filter-high-contrast
    svg {
    color: #b3b3b3 !important;
  }

  .high-contrast-color--cherry-pie,
  .assignments-table-high-contrast-lesson,
  .grades-table-high-contrast-lesson {
    svg {
      color: $contrast-lesson-color-icon !important;
    }
  }
  .high-contrast-color--chestnut-plum,
  .assignments-table-high-contrast-simbook,
  .grades-table-high-contrast-simbook {
    svg {
      color: $contrast-simbook-color-icon !important;
    }
  }

  .high-contrast-color--plum-cheese,
  .assignments-table-high-contrast-exam,
  .grades-table-high-contrast-exam {
    svg {
      color: $contrast-exam-color !important;
    }
  }
  .high-contrast-color--hairy-brown,
  .assignments-table-high-contrast-simpath,
  .grades-table-high-contrast-simpath {
    svg {
      color: $contrast-simpath-color-icon !important;
    }
  }

  .high-contrast-color--billiard-table,
  .assignments-table-high-contrast-resource,
  .grades-table-high-contrast-resource {
    svg {
      color: $contrast-resource-color-icon !important;
    }
  }

  .tile-high-contast-simbook {
    background-color: $contrast-simbook-color;
  }

  .tile-high-contast-simpath {
    background-color: $contrast-simpath-color;
  }

  .tile-high-contast-resource {
    background-color: $contrast-resource-color;
  }

  .tile-high-contast-group {
    background-color: $contrast-group-color;
  }

  .tile-high-contast-lesson {
    background-color: $contrast-lesson-color;
  }

  .tile-high-contast-exam {
    background-color: $contrast-exam-color;
  }

  .breadcrumb > li > .divider {
    color: #404040;
  }

  .label,
  .badge {
    background-color: #585858;
  }

  a,
  area,
  input:not([disabled]),
  select:not([disabled]),
  textarea:not([disabled]),
  button:not([disabled]),
  iframe,
  [tabindex],
  [contentEditable='true'] {
    &:not([tabindex='-1']) {
      &:focus {
        outline-color: $contrast-focus-color !important;
      }
    }
  }

  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='url'],
  input[type='search'],
  input[type='tel'],
  input[type='color'],
  .uneditable-input {
    &:focus {
      border-color: $contrast-focus-color;
      outline: 0;
      outline: thin dotted \9;
      box-shadow:
        inset 0 1px 1px rgba(0, 0, 0, 0.075),
        0 0 8px $contrast-focus-color;
    }
  }

  .badge-high-contrast-btn-link {
    color: #12315f !important;
  }

  .pagination {
    & ul {
      & > .active {
        & > a,
        & > span {
          color: black;
        }
      }
      & > li:first-child,
      & > li:last-child {
        & > a,
        & > span {
          color: black;
        }
      }
    }
  }
}

// Global alterations (not scoped to theme) due to
// views existing where user settings not available
.shepherd-element.shepherd-theme-dark
  .shepherd-content
  footer
  .shepherd-buttons
  li
  .shepherd-button {
  background-color: #29409a !important;
}

#footer {
  a {
    color: white;
  }
}
