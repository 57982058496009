$body-bg: #183560;

// $enable-rounded: false;
$enable-gradients: false;

$font-size-base: 0.9rem;

$h1-font-size: $font-size-base * 1.8;
$h2-font-size: $font-size-base * 1.6;
$h3-font-size: $font-size-base * 1.5;
$h4-font-size: $font-size-base * 1.4;
$h5-font-size: $font-size-base * 1.2;
$h6-font-size: $font-size-base;

$purple: #624f9a; // lesson
$orange: #d14827; // simbook
$red: #8f0f2f; // exam
$yellow: #f89c20; // simpath
$blue: #29579a; // project
$green: #1e7145; // resource

$yellow-light: #fef7df;
$yellow-medium: #e6c039;

$green-simnet: #217346;
$blue-simnet: $blue;
$red-simnet: $orange;

$red-alert: #b63927;

$overdue: #ec6039;
