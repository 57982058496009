@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  height: 100%;
}

body {
  margin: 0;
  font-family:
    Segoe UI,
    Arial,
    Helvetica,
    sans-serif !important;
}

body,
#app {
  height: 1px; /* used to get around browser bug with nested min-height: 100% */
  min-height: 100%;
}

@media print {
  body#StudentPortal {
    width: 100% !important;
    min-width: 100% !important;
  }
}

.pass-reqs-tooltip {
  .tooltip-inner {
    max-width: unset !important;
  }
  .tooltip-inner ul {
    text-align: left;
    padding-left: 20px;
  }
}

/* TailwindCSS overrides */
@layer utilities {
  .collapse {
    visibility: visible;
  }
}
